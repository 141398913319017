.navbar-light {
    background-color: var(--blueThemeColor);
    z-index: 2;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover {
    color: white;
    font-size: 1.4rem;
    font-weight: 400;
}

.navbar-light .navbar-nav .nav-link.active {
    border-bottom: .2rem solid white;
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
}

.navbar-light .navbar-nav .nav-link:focus {
    color: white;
}

.navbar-toggler {
    filter: invert(1);
    border: none;
}

.navbar-toggler-icon {
    width: 3.8rem;
    height: 2.4rem;
}

.profileDropdown a {
    font-size: 1.4rem;
    font-weight: 500;
    color: white;
    width: max-content;
}

.profileDropdown a:hover {
    color: white;
}


.dropdown-menu {
    left: -3.8rem !important;
    padding: 0.4rem;
    margin-top: 0rem;
    width: 11rem;
    opacity: 0;
    visibility: hidden;
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
    display: block !important;
}

.dropdown-menu.show {
    margin-top: .6rem;
    opacity: 1;
    visibility: visible;
}

.profileDropdown{
    text-align: -webkit-center;
}

.profileDropdown .dropdown-item {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.profileDropdown .dropdown-item:focus {
    background-color: white;
}

.profileDropdown .dropdown-item:hover {
    background-color: var(--blueThemeColor);
    color: white;
}

.brand-logo {
    width: 25rem;
}

@media (max-width:520px) {
    .brand-logo {
        width: 13.5rem;
    }
}

@media (max-width:991px) {
    .dropdown-menu {
        left: -2rem !important;
    }
}

@media (max-width:768px) {
    .dropdown-menu.show {
        position: inherit;
        left: 0rem !important;
    }
}