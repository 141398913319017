.whiteBtn {
    border: 2px solid var(--blueThemeColor);
    height: 5rem;
    color: var(--blueThemeColor);
    font-size: 1.4rem;
    font-weight: 600;
    background-color: white;
    border-radius: .8rem;
    padding: 0 2rem;
    width: max-content;
}

.whiteBtnNavbar {
    border: none;
    height: 3.5rem;
    color: var(--blueThemeColor);
    font-size: 1.4rem;
    font-weight: 600;
    background-color: white;
    border-radius: .8rem;
    padding: 0 2rem;
    width: max-content;
}

.blueBtn {
    border: none;
    height: 5rem;
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: var(--blueThemeColor);
    border-radius: .8rem;
    padding: 0 2rem;
    width: max-content;
    line-height: 1;
}

.orangeBtn {
    border: none;
    height: 5rem;
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: orange;
    border-radius: .8rem;
    padding: 0 2rem;
    width: max-content;
    line-height: 1;
}

.redBtn {
    border: none;
    height: 5rem;
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: red;
    border-radius: .8rem;
    padding: 0 2rem;
    width: max-content;
    line-height: 1;
}

.simpleBtn {
    border: 0;
    border-right: 1px solid;
    border-radius: 0;
    height: 5rem;
    color: black;
    font-size: 1.4rem;
    font-weight: 600;
    background: none;
    padding: 0 2rem;
    width: 10rem;
    line-height: 1;
    text-align: initial;
}

.simpleBtn2 {
    border: 0;
    border-left: 1px solid;
    border-radius: 0;
    height: 5rem;
    color: black;
    font-size: 1.4rem;
    font-weight: 700;
    background: none;
    padding: 0 2rem;
    width: 10rem;
    line-height: 1;
    text-align: end;
}

.dirBtn {
    padding: 2rem;
}