html {
  font-size: 62.5%;
}

:root {
  --blueThemeColor: #1E1548;
}

.containerDiv {
  padding: 3rem 4rem;
  overflow-x: hidden;
  height: 100vh;
}

.cursor {
  cursor: pointer;
}

.Toastify__toast-body>div:last-child {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

.decorNone {
  text-decoration: none;
}

.authContainer {
  margin: 6rem 0;
}

hr {
  height: 1.5px !important;
  color: black !important;
}

.shopHomeMenu {
  margin-left: 77px !important;
}

.card {
  position: unset;
}

.redHeading {
  font-size: 2rem;
  font-weight: 500;
  color: red;
  margin-bottom: 0;
}

.mainHeading {
  font-size: 4rem;
  font-weight: 700;
  color: var(--blueThemeColor);
}

.subHeading {
  font-size: 2rem;
  font-weight: 500;
  color: var(--blueThemeColor);
  margin-bottom: 20px;
}

.accordion-button:not(.collapsed) {
  background: none !important;
}

.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
  color: var(--blueThemeColor);
}

.accordion-item {
  border: 0;
  border-bottom: 1px solid var(--blueThemeColor);
}

.accordion-body {
  background-color: var(--blueThemeColor);
}

.accordion-body .description {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0;
  color: white;
}

.paragraph {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--blueThemeColor);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.chatBoxDivHidden {
  position: fixed;
  right: 2rem;
  bottom: 0;
  height: 4rem;
  width: 30rem;
  background-color: var(--blueThemeColor);
  color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  cursor: pointer;
}

.onlineIcon {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #4CAF50;
  margin-bottom: 0;
  display: inline-block;
}

.chatBoxDivHidden h4 {
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 500;
  margin-left: .7rem;
  text-transform: capitalize;
}

.chatBoxDivHidden .msgCount {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 1.1rem;
}

.fixedSlider {
  position: fixed;
  left: 3rem;
  top: 10rem;
  width: inherit;
}

.fixedSlider img {
  object-fit: contain;
}

.chatBoxDivShow {
  position: fixed;
  right: 2rem;
  bottom: 0;
  z-index: 2;
  width: 30rem;
}

.customScroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.customScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #CAE9FC;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.customScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--blueThemeColor);
}

.marginTopDiv {
  margin-top: 10rem;
}

.progressbar-container {
  width: 100%;
}

.count-text {
  font-size: 2rem;
  font-weight: 500;
  text-align: end;
}

.mapDiv {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}

.btnOnMap {
  position: fixed;
  z-index: 9;
  top: 40%;
  left: 0;
  background: #ffffff8c;
  padding: 2rem 2rem 2rem 0;
}

.btnOnMap button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

::-ms-reveal {
  display: none;
}

.profileMap {
  height: 15rem;
  position: relative;
}

.profileMap div {
  border-radius: 1rem;
}

.chatBlocksDiv {
  position: fixed;
  bottom: 0;
  right: 3rem;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 2rem;
  z-index: 2;
}

.chatContainer2 {
  width: 30rem;
  position: absolute;
  bottom: 0;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: silver;
}

/* Media Query for Small Devices */
@media(max-width:768px) {
  html {
    font-size: 50%;
  }

  .fixedSlider {
    position: unset;
  }
}

/* Media Query for Small Devices */